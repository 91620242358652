@if ($illusion-extendalize == true and $illusion-extendalize-list != false) or $illusion-extendalize-list == true {
  /**
   * List
   *
   * 1. Remove top-margin, margins should live on bottom of elements
   * 2. Set bottom margin to default line-height and remove for last-child
   */

  ul, ol {
    margin-top: $illusion-extendalize-list-margin-top; /* 1 */
    @include content-block($illusion-extendalize-list-margin-bottom); /* 2 */
  }
}
