// Spacing
//
// Responsive spacing that follows the grid breakpoints

@mixin spacing($sides: $illusion-spacing-sides, $type: $illusion-spacing-type, $multiplier: $illusion-spacing-multiplier, $fromto: $illusion-spacing-fromto, $lastchildnone: $illusion-spacing-lastchildnone, $minus: $illusion-spacing-minus) {

  // Give a warning when $multiplier is not dividable by 0.5
  @if ($multiplier % 0.5 != 0) {
    @warn 'The `multiplier` in the `spacing` mixin is designed to work best with a number dividable by 0.5';
  }

  @if ($type != padding and $type != margin) {
    @warn 'It might be best to use the spacing function instead of the spacing mixin for what you are trying to do.';
  }

  // Get new SCSS map
  $grid-breakpoints: grid-breakpoints($fromto);

  // Loop
  @each $breakpoint, $values in $grid-breakpoints {

    //Get values
    $spacing: map-get($values, gutter);
    $width: map-get($values, width);
    $side: nth($sides, 1);
    $skip: getsides($sides);
    $spacing: map-get($values, gutter) * $multiplier;
    $width: map-get($values, width);
    $subtract: $minus;

    // Only run between starting and stopping breakpoints
    @include ifBreakpoint($width) {

      // Top
      @if ($side == all or $side == vertical or $side == top) and $skip != top {
        @if ($side == all or $side == vertical) and ($skip != bottom) {
          @if ($minus % 2 != 0) { @warn '$minus should be an even number'; }
          $subtract: calc($minus / 2);
        }
        #{$type}-top: $spacing - $subtract;

        @if $lastchildnone != false {
          &:last-child {
            #{$type}-top: 0;
          }
        }
      }

      // Right
      @if ($side == all or $side == horizontal or $side == right) and $skip != right {
        @if ($side == all or $side == horizontal) and ($skip != left) {
          @if ($minus % 2 != 0) { @warn '$minus should be an even number'; }
          $subtract: calc($minus / 2);
        }
        #{$type}-right: $spacing - $subtract;

        @if $lastchildnone != false {
          &:last-child {
            #{$type}-right: 0;
          }
        }
      }

      // Bottom
      @if ($side == all or $side == vertical or $side == bottom) and $skip != bottom {
        @if ($side == all or $side == vertical) and ($skip != top) {
          @if ($minus % 2 != 0) { @warn '$minus should be an even number'; }
          $subtract: calc($minus / 2);
        }
        #{$type}-bottom: $spacing - $subtract;

        @if $lastchildnone != false {
          &:last-child {
            #{$type}-bottom: 0;
          }
        }
      }

      // Left
      @if ($side == all or $side == horizontal or $side == left) and $skip != left {
        @if ($side == all or $side == horizontal) and ($skip != right) {
          @if ($minus % 2 != 0) { @warn '$minus should be an even number'; }
          $subtract: calc($minus / 2);
        }
        #{$type}-left: $spacing - $subtract;

        @if $lastchildnone != false {
          &:last-child {
            #{$type}-left: 0;
          }
        }
      }
    }
  }
}
