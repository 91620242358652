// Namespacing entire theme to enclose variables so they won't bleed out to other themes

.npo3fm,
.npo3fmkx {

  // ==========================================================================
  // Default variables
  // ==========================================================================

  // Color
  $primary-color: #279b1c;
  $primary-color-color: #000;
  $secondary-color: #000;
  $secondary-color-color: #fff;
  $tertiary-color: #e6e6e6;
  $tertiary-color-color: #0b1116;
  $quaternary-color: #0b1116;
  $quaternary-color-color: #000;

   // Type
   $base-font-family: "NPO Nova Text", sans-serif;
   $alternate-font-family: "NPO Nova Headline", sans-serif;

  // Paths
  $assets-path: $assets-path-root + '/npo3fm';

  // Logo
  $logo-width: 48px;

  // Custom
  $theme-title-transform: none;

  // ==========================================================================
  // Maps (add -theme suffix)
  // ==========================================================================

  // Top
  $top-theme: (
          background: $tertiary-color-color,
          color: $secondary-color,
  );

  // Header
  $header-theme: (
          title-font-family: $alternate-font-family,
          title-font-weight: $weight-bold,
          title-text-transform: $theme-title-transform,
          content-text-transform: $theme-title-transform,
          footer-color: $tertiary-color-color,
          footer-background: #fff,
          background: linear-gradient(117.3deg, rgb(255, 255, 40) -0.08%, rgb(255, 255, 40) -0.07%, rgb(53, 211, 39) 79.49%),
          color: $secondary-color,
  );

  // Show
  $show-theme: (
          name-font-family: $alternate-font-family
  );

  // Navigation
  $navigation-theme: (
          background: $tertiary-color-color,
          color: $secondary-color-color,
          font-family: #{$alternate-font-family},
          link-hover-color: $secondary-color-color,
          link-active-color: $primary-color,
          link-font-weight: $base-weight-bold,
          link-text-transform: $theme-title-transform,
          charlie-font-size: 16px
  );

  // Nieuws
  $nieuws-theme: (
          color: $tertiary-color-color,
          background: transparent,
          content-border-left: 2px solid $primary-color,
          category-font-family: $alternate-font-family,
          category-font-weight: $base-weight-bold
  );

  // Card
  $card-theme: (
          color: $secondary-color,
          item-artist-font-family: #{$base-font-family},
          item-artist-text-transform: none,
          item-time-color: $secondary-color-color,
          item-time-background-color: $secondary-color,
          item-time-font-family: $alternate-font-family,
          item-time-font-weight: $base-weight-bold,
          item-time-highlight-color: $primary-color-color,
          item-time-highlight-background: linear-gradient(117.3deg, rgb(255, 255, 40) -0.08%, rgb(255, 255, 40) -0.07%, rgb(53, 211, 39) 79.49%),
  );

  // Frequencies
  $frequencies-theme: (
          map: true,
          row-color: #000,
          row-background: #fff,
          row-button-color: $primary-color-color,
          row-button-background: $primary-color,
          title-font-family: $alternate-font-family,
          title-font-weight: $base-weight-bold
  );


  // ==========================================================================
  // Studiomessenger
  // ==========================================================================

  $nposm-header-background: #000000;
  $nposm-header-color: #ffffff;


  // Styles
  @import "style";


  // ==========================================================================
  // Extra styling (keep to a minimum)
  // ==========================================================================

  // "Body" background

  .scrollable {
    background-color: #fff;
    color: $tertiary-color-color;
  }

  .header {
    position: relative;

    @include breakpoint($charlie) {
        overflow: hidden;
    }

    &::before {
        background-image: url('#{$assets-path}/bg-3fm-left.svg');
        background-repeat: no-repeat;
        content: '';
        height: 96px;
        left: 96px;
        position: absolute;
        top: 0;
        width: 57px;

        @include breakpoint($charlie) {
            height: 176px;
            left: 176px;
            width: 104px;
        }
    }

    &::after {
        background-image: url('#{$assets-path}/bg-3fm-right.svg');
        background-repeat: no-repeat;
        content: '';
        height: 123px;
        right: -6px;
        position: absolute;
        top: 0;
        width: 86px;

        @include breakpoint($charlie) {
            height: 234px;
            right: -7px;
            width: 160px;
        }
    }

    &__body,
    &__footer {
        position: relative;
        z-index: 1;
    }
  }

  .advertisement-title {
    font-family: $alternate-font-family;
  }
}
