/* ==========================================================================
   Backdrop
   ========================================================================== */

.backdrop {
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.is-visible {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
