// Namespacing entire theme to enclose variables so they won't bleed out to other themes

.nporadio5-sterrennl {

  // ==========================================================================
  // Default variables
  // ==========================================================================

  // Color
  $primary-color: #ea1f63;
  $primary-color-color: #fff;
  $secondary-color: #040f43;
  $secondary-color-color: #fff;

  // Type
  $base-font-family: "NPO Nova Text", sans-serif;
  $base-font-weight: 400;
  $base-weight-bold: 700;

  // Paths
  $assets-path: $assets-path-root + '/nporadio5/sterrennl';

  // Logo
  $logo-width: 48px;


  // ==========================================================================
  // Maps (add -theme suffix)
  // ==========================================================================

  // Top
  $top-theme: (
          background: $secondary-color,
          color: $secondary-color-color,
  );

  // Header
  $header-theme: (
          color: #fff,
          background: $secondary-color #{url('#{$assets-path}/header-background.png')} center center / cover no-repeat,
  );

  // Navigation
  $navigation-theme: (
          color: $secondary-color-color,
          background: $secondary-color,
          charlie-font-size: 16px,
          link-hover-color: #ffffff,
          link-active-color: $primary-color,
  );

  // Card
  $card-theme: (
          border-radius: 5px,
          background: #fff,
          item-time-color: $primary-color-color,
          item-time-background-color: $primary-color,
          item-time-border-radius: 4px,
          item-time-highlight-color: $secondary-color-color,
          item-time-highlight-background: $secondary-color,
          item-image-border-radius: 5px 0 0 5px,
  );

  // Nieuws
  $nieuws-theme: (
          background: transparent,
          content-border-left: 2px solid $primary-color,
          category-font-weight: $base-weight-bold,
          category-color: $primary-color,
          image-border-radius: 5px 0 0 5px,
  );


  // ==========================================================================
  // Studiomessenger
  // ==========================================================================

  $nposm-header-background: $primary-color;
  $nposm-header-color: $primary-color-color;


  // Styles
  @import "style";

}
