/* ==========================================================================
   Frequencies
   ========================================================================== */

/* Titles */
.frequencies__title,
.dab__map_title,
.frequencies__dab__title,
.frequencies__download__title {
  @include property(font-family, $frequencies, title-font-family);
  @include property(font-weight, $frequencies, title-font-weight);
  @include property(font-style, $frequencies, title-font-style);
  @include property(font-size, $frequencies, title-font-size);
  @include property(text-transform, $frequencies, title-text-transform);
}

.frequencies__title,
.dab__map_title {
  @include property(font-size, $frequencies, frequencies-map-title-font-size);
}

.frequencies__dab__title,
.frequencies__download__title {
  @include property(font-size, $frequencies, dab-download-title-font-size);
  @include property(color, $frequencies, dab-download-title-color);
}

/* Content */
.frequencies__body,
.frequencies__download__body {
  @include property(font-family, $frequencies, frequencies-body-font-family);
  @include property(font-style, $frequencies, frequencies-body-font-style);
}

/* Toggle buttons */
.frequency__buttons {
  button {
    @include property(color, $frequencies, button-color);
    @include property(background, $frequencies, button-background);
    @include hover() {
      @include property(border-bottom-color, $frequencies, button-hover-border-color);
    }

    &.active {
      @include property(color, $frequencies, button-active-color);
      @include property(background, $frequencies, button-active-background);
    }
  }
}

/* Table */
.frequency__row {
  @include property(color, $frequencies, row-color);
  @include property(background, $frequencies, row-background);
  @include property(font-family, $frequencies, row-font-family);
  @include property(font-weight, $frequencies, row-font-weight);
  @include property(text-transform, $frequencies, row-text-transform);

  .frequency {
    &, a {
      @include property(color, $frequencies, row-button-color);
      @include property(background, $frequencies, row-button-background);
      @include property(border-radius, $frequencies, row-button-border-radius);
    }
  }
}

/* Download list */
.frequencies__download {
  a {
    @include property(color, $frequencies, download-color);
  }
}

/* Map */
@if map-get($frequencies, map) == true {
  .dab__map {
    @include property(background-image, $frequencies, map-background);
  }
}
