/* ==========================================================================
   Navigation
   ========================================================================== */

.navigation {
  position: sticky;
  z-index: 3;
  top: 0;
  flex: 0 0 auto;
  line-height: 24px;
  @include spacing(horizontal);

  @include breakpoint($charlie) {
    line-height: 32px;
  }
}

/* Menu
   ========================================================================== */

.menu {
  @include reset();
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;

  @include breakpoint($bravo) {
    flex-wrap: nowrap;
  }
}

/* Menu item
   ========================================================================== */

.menu__item {
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  text-align: center;
}

/* Menu link
   ========================================================================== */

.menu__link {
  display: block;
  text-decoration: none;
  margin-top: $spacing-xs;
  margin-bottom: $spacing-xs;
  padding-right: $spacing-s;
  padding-left: $spacing-s;
  @include transition(all);
  outline: none;

  @include breakpoint($charlie) {
    margin-top: $spacing-s;
    margin-bottom: $spacing-s;
    padding-right: $spacing-m;
    padding-left: $spacing-m;
  }
}
