/* ==========================================================================
   Frequenties
   ========================================================================== */

/* Wrapper */
.frequencies-wrapper {
  @include breakpoint($charlie, $delta-min) {
    // Viewport width minus spacing * 3 and add 10px for scrollbar
    max-width: calc(100vw - (300px + (20px * 3) + 10px));
  }
  @include breakpoint($delta, $echo-min) {
    // Viewport width minus spacing * 3 and add 10px for scrollbar
    max-width: calc(100vw - (300px + (30px * 3) + 10px));
  }
}

/* Spacing between blocks */
.frequencies__body,
.frequencies__table,
.dab__map_container,
.frequencies__dab,
.frequencies__download {
  @include spacing(bottom, margin, $lastchildnone: true);
}

/* Titles */
.frequencies__title,
.dab__map_title,
.frequencies__dab__title,
.frequencies__download__title {
  line-height: 1.2em;
  margin-bottom: 10px;
}

/* Toggle buttons */
.frequency__buttons {
  display: flex;
  margin-bottom: 10px;

  button {
    @include reset();
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    border-bottom: 2px solid transparent;
  }
}

/* Table */
.frequency__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  font-weight: $weight-bold;
  @include content-block(20px);

  .label,
  .frequency {
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  .frequency {
    &, a {
      display: flex;
      align-items: center;
      padding: .25em .5em;
      margin-right: 16px;
      text-decoration: none;
      white-space: nowrap;
      font-size: 14px;
      line-height: 20px;
      height: 26px;
    }

    a:only-child {
      margin-right: 0;
    }
  }
}

/* Download list */
.frequencies__download {
  ul {
    @include reset();
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  li {
    padding-right: 12px;

    @include content-block(6px);
    @include pseudo(after) {
      top: 50%;
      right: 0;
      margin-top: -3px;
      width: 6px;
      height: 6px;
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
    }
  }
}

/* Map */
.dab__map {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100px;
  background: transparent no-repeat center top;
  background-size: contain;
  @include ratio-block(3 by 3.5);
  @include spacing(top, margin, 1, $charlie);
  @include spacing(right, margin, 1, $charlie);

  @include breakpoint($charlie) {
    position: absolute;
    top: 0;
    right: 0;
  }

  &:before {
    width: 100%;
  }
}

/* Map when advertisement is "active" */
.grid__item {
  &:nth-last-child(2):first-child {
    & + .grid__item {
      .dab__map {
        @include breakpoint($charlie) {
          background-position: center bottom $player-height;
          @include ratio-block(3 by 6.5);
        }
      }
      .frequencies-wrapper {
        @include breakpoint($charlie, $echo-min) {
          max-width: 100%;
        }
      }
    }
  }
}
