/* ==========================================================================
   Logo
   ========================================================================== */

.logo-container {
  display: inline-block;
  vertical-align: middle;
}

.logo {
  display: block;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left;
}
