/* Button
 ========================================================================== */

.player__button {
  @include property(font-family, $player, channel-button-font-family);
  @include property(font-weight, $player, channel-button-font-weight);
  @include property(text-transform, $player, channel-button-text-transform);
}

.player__button--live {
  &.is-active::after {
    @include property(background, $player, highlight-color);
  }
}

/* Volume slider
   ========================================================================== */

.volume-slider {
  .rc-slider-track {
    @include property(background-color, $player, highlight-color);
  }

  .rc-slider-handle.rc-slider-handle {
    @include property(background-color, $player, highlight-color);
    @include property(border-color, $player, highlight-color);

    &:focus {
      box-shadow: 0 0 0 1px isset($primary-color);
    }
  }
}
