/* ==========================================================================
   Advertisement
   ========================================================================== */

.advertisement-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;

  @include spacing(left, padding, 1, $charlie);
  @include breakpoint($charlie) {
    justify-content: flex-end;
  }
}

.advertisement-title {
  display: block;
  text-align: center;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: $weight-bold;
}

.advertisement {
  position: relative;
  width: auto;
  min-width: 300px;
  margin: 0 auto;
}
