// Namespacing entire theme to enclose variables so they won't bleed out to other themes

.nporadio5 {
    // ==========================================================================
    // Default variables
    // ==========================================================================

    // Color
    $primary-color: #f3b229;
    $primary-color-color: #000;
    $secondary-color: #325D92;
    $secondary-color-color: #fff;
    $tertiary-color: #325D92;
    $tertiary-color-color: #000;
    $quaternary-color: #f9f9f9;
    $quaternary-color-color: #000;

    // Type
    $base-font-family: "NPO Nova Text", sans-serif;
    $alternate-font-family: "NPO Nova Headline", sans-serif;

    // Paths
    $assets-path: $assets-path-root + '/nporadio5';

    // Logo
    $logo-width: 48px;


    // ==========================================================================
    // Maps (add -theme suffix)
    // ==========================================================================

    // Top
    $top-theme: (
        background: $secondary-color,
        color: $tertiary-color-color,
    );

    // Header
    $header-theme: (
        background: #fff,
        color: #000,
        font-weight: 600,
        footer-background: $secondary-color,
        footer-color: #fff,
    );

    $show-theme: (
        name-font-family: $alternate-font-family,
        name-font-size: 20px,
        name-font-weight: 600,
        time-font-size: 16px,
        time-font-weight: 600,
    );

    // Navigation
    $navigation-theme: (
        background: $secondary-color,
        charlie-font-size: 16px,
        color: $secondary-color-color,
        font-size: 16px,
        link-active-color: #ffffff,
        link-active-underline-background: $primary-color,
        link-active-underline: true,
        link-hover-color: #ffffff,
    );

    // Card
    $card-theme: (
        background: #fff,
        border-radius: 5px,
        color: #000,
        item-artist-font-size: 16px,
        item-image-border-radius: 5px 0 0 5px,
        item-time-background-color: $tertiary-color,
        item-time-border-radius: 4px,
        item-time-color: $secondary-color-color,
        item-time-font-weight: 600,
        item-time-highlight-background: $primary-color,
        item-time-highlight-color: $primary-color-color,
        item-track-font-family: $alternate-font-family,
        item-track-font-size: 16px,
        item-track-font-weight: 600,
    );

    // Nieuws
    $nieuws-theme: (
        background: transparent,
        border-radius: 5px,
        category-color: $tertiary-color,
        category-font-size: 16px,
        category-font-weight: 600,
        color: #000,
        content-border-left: 2px solid $primary-color,
        image-border-radius: 5px 0 0 5px,
        title-font-family: $alternate-font-family,
    );


    // Frequencies
    $frequencies-theme: (
        row-background: #f9f9f9,
        row-button-background: $tertiary-color,
        row-button-border-radius: 5px,
        row-button-color: $secondary-color-color,
        row-color: $tertiary-color-color,
    );


    // ==========================================================================
    // Studiomessenger
    // ==========================================================================

    $nposm-header-background: $primary-color;
    $nposm-header-color: $primary-color-color;


    // Styles
    @import "style";


    // ==========================================================================
    // Overrides
    // ==========================================================================

    .header__footer {
        font-size: 16px;
    }

    .menu__link {
        font-family: $alternate-font-family;
    }

    .subchannel-button {
        color: #fff;
    }

    .nieuws-item__title {
        font-size: 16px;
        font-weight: 600;
    }

    .card-item__presenter {
        font-size: 16px;
    }

    .card-item__time {
        font-size: 14px;
    }

    .frequency__row {
        font-family: $alternate-font-family;
        font-size: 16px;
    }
}
