/* ==========================================================================
   Header
   ========================================================================== */

.header {
  @include property(padding-right, $header, padding-right);
  @include property(background, $header, background);
  @include property(color, $header, color);
}


/* Header footer
   ========================================================================== */

.header__footer {
  @include property(background, $header, footer-background);
  @include property(color, $header, footer-color);
  @include property(font-weight, $header, font-weight);

  @include breakpoint($charlie) {
    @include property(margin-top, $header, footer-separator-charlie-margin-top);
    @include property(padding-top, $header, footer-separator-charlie-padding-top);

    @include pseudo(before) {
      @if (map-get($header, footer-separator-charlie-border-top-color)) {
        @include property(border-top-color, $header, footer-separator-charlie-border-top-color);
      }
    }
  }
}
