// Compile omroepen logos
// Default black logos, overwriteable per theme to make them white

@mixin omroepen($type: black) {
  @each $omroep, $values in $omroepen {
    $width: map-deep-get($values, width);
    $height: map-deep-get($values, height);
    $defaultheight: map-deep-get($values, defaultheight);

    .omroep-#{$omroep} {
      @include ratio-block($width by $height);
      margin-top: #{calc((18 - ($defaultheight * 0.75))/2)-1}px;
      width: #{($width * calc($defaultheight / $height) * 0.75)}px;
      background: url('/assets/images/omroepen/#{$omroep}/logo-#{$type}.svg');

      @include breakpoint($bravo) {
        width: #{$width * calc($defaultheight / $height)}px;
        margin-top: #{calc((23 - $defaultheight)/2)-1}px;
      }
    }
  }
}
