// Namespacing entire theme to enclose variables so they won't bleed out to other themes

.nporadio1 {

  // ==========================================================================
  // Default variables
  // ==========================================================================

  // Color
  $primary-color: #1952ff;
  $primary-color-color: #ffffff;
  $secondary-color: #002964;
  $secondary-color-color: #ffffff;
  $tertiary-color: #f1812b;
  $tertiary-color-color: #ffffff;

  $news-podcast-color: #003576;
  $news-background-color: #3BCD8C;
  $news-live-color: #E23135;
  $news-sport-color: #F1812B;


  // Type
  $base-font-family: 'NPO Nova Text', sans-serif;

  // Paths
  $assets-path: $assets-path-root + '/nporadio1';

  // Logo
  $logo-width: 48px;


  // ==========================================================================
  // Maps (add -theme suffix)
  // ==========================================================================

  // Top
  $top-theme: (
          background: $primary-color,
          color: $primary-color-color,
  );

  // Header
  $header-theme: (
          color: #000,
          background: #{$secondary-color} #{url('#{$assets-path}/header-background.jpg')} center center / cover no-repeat,
          content-font-size: 16px
  );

  // Show
  $show-theme: (
          name-font-size: 20px,
  );

  // Navigation
  $navigation-theme: (
          color: $primary-color-color,
          background: $primary-color,
          link-hover-color: lighten($primary-color, 35%),
          link-active-color: #fff,
          link-active-underline: true,
          link-active-underline-bottom: 2px,
          charlie-font-size: 16px,
  );

  // Card
  $card-theme: (
          border-radius: 5px,
          item-time-color: $secondary-color-color,
          item-time-background-color: $secondary-color,
          item-time-highlight-color: $primary-color-color,
          item-time-highlight-background: $primary-color,
          item-time-border-radius: 12px,
          item-image-border-radius: 5px 0 0 5px,
  );

  // Nieuws
  $nieuws-theme: (
          border-radius: 5px,
          image-border-radius: 5px,
          content-margin-left: 0,
          content-border-left: none,
          category-display: inline-block,
          category-border-radius: 5px
  );

  // Frequency
  $frequencies-theme: (
          row-color: #000,
          row-background: #f9f9f9,
          row-button-color: $primary-color-color,
          row-button-background: $primary-color,
          row-button-border-radius: 13px,
          map: true
  );


  // ==========================================================================
  // Studiomessenger
  // ==========================================================================

  $nposm-header-background: $primary-color;
  $nposm-header-color: $primary-color-color;


  // Styles
  @import "style";


  // ==========================================================================
  // Extra styling (keep to a minimum)
  // ==========================================================================

  .header {
    font-size: 16px;
    .show__name {
        font-family: "NPO Nova Headline", sans-serif;
    }

  }

  .nieuws-category__title {
    padding: .1em .5em;
    color: #fff;
    background: $primary-color;
    border-radius: 10px;
    font-weight: 550;

    &.color--live {
      background: $news-live-color;
    }
    &.color--podcast {
      background: $news-podcast-color;
    }
    &.color--sport {
      background: $news-sport-color;
    }
    &.color--background {
      background: $news-background-color;
    }
  }
}
