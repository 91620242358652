/* ==========================================================================
   Nieuws
   ========================================================================== */

.nieuws-item {
  @include property(background, $nieuws, background);
  @include property(color, $nieuws, color);
  @include property(font-size, $nieuws, font-size);
  @include property(border-radius, $nieuws, border-radius);

  @include breakpoint($delta) {
    @include property(font-size, $nieuws, delta-font-size);
  }
}

.nieuws-item__content {
  @include property(padding, $nieuws, content-padding);
  @include property(text-transform, $nieuws, content-text-transform);
  @include property(font-weight, $nieuws, content-font-weight);
  @include property(margin-left, $nieuws, content-margin-left);
  @include property(border-left, $nieuws, content-border-left);
}

.nieuws-category__title {
  @include property(color, $nieuws, category-color);
  @include property(font-family, $nieuws, category-font-family);
  @include property(font-size, $nieuws, category-font-size);
  @include property(font-weight, $nieuws, category-font-weight);
  @include property(border-radius, $nieuws, category-border-radius);
  @include property(display, $nieuws, category-display);
}

.nieuws-item__title {
  @include property(font-family, $nieuws, title-font-family);
}

.nieuws-item__image {
  @include property(border-radius, $nieuws, image-border-radius);
}
