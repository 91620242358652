.nporadio2-souljazz {
    // ==========================================================================
    // Default variables
    // ==========================================================================

    // Color
    $primary-color: #d9151b;
    $primary-color-color: #ffffff;
    $secondary-color: #22282e;
    $secondary-color-color: white;
    $tertiary-color: #f9f9f9;
    $tertiary-color-color: $secondary-color;
    $quaternary-color: #d9151b;

    // Type
    $base-font-family: "NPO Nova Text", sans-serif;
    $alternate-font-family: "NPO Nova Headline", sans-serif;

    // Paths
    $assets-path: $assets-path-root + "/nporadio2/souljazz";

    // Logo
    $logo-width: 48px;

    // Custom
    $theme-title-transform: none;

    // ==========================================================================
    // Maps (add -theme suffix)
    // ==========================================================================

    // Top
    $top-theme: (
        background: $secondary-color,
        color: $secondary-color-color
    );

    // Header
    $header-theme: (
        color: $tertiary-color-color,
        background: #ffffff,
        content-font-size: 16px
    );

    // Show
    $show-theme: (
        name-font-family: $alternate-font-family,
        name-font-size: 20px,
        name-font-weight: $base-weight-bold
    );

    // Navigation
    $navigation-theme: (
        font-family: $alternate-font-family,
        font-size: 16px,
        font-weight: $base-weight-bold,
        link-active-color: $quaternary-color
    );

    // Card
    $card-theme: (
        border-radius: 5px,
        background: #fff,
        item-time-color: $secondary-color-color,
        item-time-background-color: $secondary-color,
        item-time-border-radius: 4px,
        item-time-font-family: $alternate-font-family,
        item-time-font-weight: $base-weight-bold,
        item-time-highlight-color: $primary-color-color,
        item-time-highlight-background: $primary-color,
        item-image-border-radius: 5px 0 0 5px
    );

    // Nieuws
    $nieuws-theme: (
        border-radius: 5px,
        color: #000,
        background: transparent,
        content-border-left: 2px solid $primary-color,
        category-font-family: $alternate-font-family,
        category-font-weight: $base-weight-bold,
        category-color: $primary-color,
        image-border-radius: 5px 0 0 5px
    );

    // Frequencies
    $frequencies-theme: (
        title-font-family: $alternate-font-family,
        title-font-weight: $base-weight-bold,
        title-font-size: 20px
    );

    // ==========================================================================
    // Studiomessenger
    // ==========================================================================

    $nposm-header-background: $primary-color;
    $nposm-header-color: $primary-color-color;

    // Styles
    @import "style";
}
