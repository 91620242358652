@if ($illusion-extendalize == true and $illusion-extendalize-boxsizing != false) or $illusion-extendalize-boxsizing == true {
  /**
   * Border-box everything
   */

  html {
    box-sizing: $illusion-extendalize-html-box-sizing;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
}
