/* ==========================================================================
   Scrollable
   ========================================================================== */

.scrollable {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  padding-bottom: $player-height;
  background: white; // Cover default loader
  -webkit-overflow-scrolling: touch
}
