/* ==========================================================================
   Subchannel button
   ========================================================================== */

.subchannel-button {
  @include property(font-weight, $player, channel-button-font-weight);
  @include property(text-transform, $player, channel-button-text-transform);
}

/* ==========================================================================
   Subchannel selector list
   ========================================================================== */

.subchannel-selector {
  li {
    @include property(font-size, $player, channel-item-font-size);
    @include property(font-weight, $player, channel-item-font-weight);
    @include property(text-transform, $player, channel-item-text-transform);
  }
}
