/* ==========================================================================
   Set-up for flexbox
   ========================================================================== */

html {
  @include font-smoothing();
}

html,
body,
#root,
#root > div {
  height: 100%;
}
