// Namespacing entire theme to enclose variables so they won't bleed out to other themes

.npofunx {

  // ==========================================================================
  // Default variables
  // ==========================================================================

  // Color
  $primary-color: #B79FCF;
  $primary-color-color: #2F3E86;
  $secondary-color: #000;
  $secondary-color-color: #FFF;
  $tertiary-color: #2F3E86;
  $tertiary-color-color: $primary-color;
  $primary-accent-color: #291436;


  // Type
  $base-font-family: "NPO Nova Text", sans-serif;
  $alternate-font-family: "NPO Nova Headline", sans-serif;
  $base-weight-bold: 700;
  $alternate-font-style: none;

  $primary-font: "NPO Nova Text", sans-serif;
  $secondary-font: "NPO Nova Headline", sans-serif;

  // Paths
  $assets-path: $assets-path-root + '/npofunx';

  // Logo
  $logo-width: 104px;

  // Custom
  $theme-title-transform: none;


  // ==========================================================================
  // Maps (add -theme suffix)
  // ==========================================================================

  // Top
  $top-theme: (
          background: $secondary-color,
          color: $secondary-color-color,
  );

  // Header
  $header-theme: (
          color: #FFF,
          background: $primary-accent-color #{url('#{$assets-path}/stripes-background.svg')} center center / cover no-repeat,
  );

  // Show
  $show-theme: (
          name-font-family: $alternate-font-family,
          name-font-style: $alternate-font-style,
  );

  // Navigation
  $navigation-theme: (
          color: $secondary-color-color,
          background: $secondary-color,
          font-family: $alternate-font-family,
          font-style: $alternate-font-style,
          charlie-font-size: 16px,
          link-hover-color: $secondary-color-color,
          link-active-color: $secondary-color-color,
          link-active-underline: true,
          link-active-underline-background: #7B38B4,
  );

  // Card
  $card-theme: (
          border-radius: 5px,
          font-family: $secondary-font,
          font-weight: 700,
          color: #000,
          background: #fff,
          item-time-color: $primary-color-color,
          item-time-background-color: $primary-color,
          item-time-highlight-color: $tertiary-color-color,
          item-time-highlight-background: $tertiary-color,
          item-image-border-radius: 5px 0 0 5px,

  );

  // Nieuws
  $nieuws-theme: (
          color: $secondary-color-color,
          background: $primary-accent-color,
          content-border-left: none,
          content-margin-left: 2px,
          category-font-weight: 700,
          category-color: $primary-color,
          image-border-radius: 5px 0 0 5px,
  );

  // Frequencies
  $frequencies-theme: (
          title-font-family: $alternate-font-family,
          title-font-style: $alternate-font-style,
          title-font-weight: $base-weight-bold,
          title-text-transform: none,
          map: true,
          row-color: #000,
          row-background: #f9f9f9,
          row-button-color: $primary-color-color,
          row-button-background: $primary-color,
          row-button-border-radius: 5px,
  );


  // ==========================================================================
  // Studiomessenger
  // ==========================================================================

  $nposm-header-background: #82C8B4;
  $nposm-header-color: #ffffff;


  // Styles
  @import "style";


  // ==========================================================================
  // Extra styling (keep to a minimum)
  // ==========================================================================

  .navigation,
  .show__name,
  .advertisement-title {
    text-transform: none;
  }

  .frequency,
  .advertisement-title {
    font-family: $alternate-font-family;
    font-style: $alternate-font-style;
  }

  .menu__link.is-active::after {
    transform: skewX(-70deg);
  }

  .show__time, .header__footer, .card-item__subtitle {
    font-family: "NPO Nova Text", sans-serif;
    font-weight: 400;
  }

  .header__footer {
    background-color: $secondary-color;
    color: #FFF;
  }

   .card-item .card-item__track {
    font-weight: 400;
    width: 47px;
    justify-content: center;
  }

   .card-item:first-child .card-item__track {
    font-weight: 700;
  }

  .nieuws-category__title {
    font-size: 14px;
    font-family: $primary-font;
    font-weight: 700;
    text-transform: none;
  }


  // Studiomessenger extra styling
  // ==========================================================================

  .npo-studio-messenger {
    .messenger-page-header {
      background:linear-gradient(to right, #6d2f91 0, #4cc7a8 100%);
    }
  }
}
