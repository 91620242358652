// Cover the entire parent element

@mixin coverall($offset: $illusion-coverall-offset, $top: $illusion-coverall-top, $right: $illusion-coverall-right, $bottom: $illusion-coverall-bottom, $left: $illusion-coverall-left, $position: $illusion-coverall-position, $reset: $illusion-coverall-reset) {
  @if $position != false {
    position: $position;
  }
  @if $top == true {
    top: $offset;
  } @else if $top != false {
    top: $top;
  }
  @if $right == true {
    right: $offset;
  } @else if $right != false {
    right: $right;
  }
  @if $bottom == true {
    bottom: $offset;
  } @else if $bottom != false {
    bottom: $bottom;
  }
  @if $left == true {
    left: $offset;
  } @else if $left != false {
    left: $left;
  }
  @if $reset == true {
    position: $illusion-coverall-reset-position;
    top: $illusion-coverall-reset-top;
    right: $illusion-coverall-reset-right;
    bottom: $illusion-coverall-reset-bottom;
    left: $illusion-coverall-reset-left;
  }
}
