/* ==========================================================================
   Grid
   ========================================================================== */

.grid {
  position: relative; // To position the frequency map properly
  flex: 1 1 auto;
  max-width: 100%;
  @include spacing(horizontal);

  @include breakpoint($charlie) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.grid__item {
  overflow: hidden; // Keep layout in check when items have very long words (or written urls)
}

.grid__item:first-child {
  flex: 1 0 auto;
  @include spacing(top, padding, 1.5);
}

.grid__item:last-child {
  flex: 999 1 auto;
  @include spacing(vertical, padding, 1.5);
  @include breakpoint($delta) {
    max-width: 704px;
  }
}

.grid__item:only-child {
  flex: 1 1 704px;
  margin-right: auto;
}
