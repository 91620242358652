@if ($illusion-extendalize == true and $illusion-extendalize-fieldset != false) or $illusion-extendalize-fieldset == true {
  /**
   * Fieldset
   */

  fieldset {
    width: $illusion-extendalize-fieldset-width;
    border: $illusion-extendalize-fieldset-border;
    padding: $illusion-extendalize-fieldset-padding;
    margin: $illusion-extendalize-fieldset-margin;

    legend {
      @include content-block($illusion-extendalize-legend-margin-bottom);
    }
  }
}
