// Namespacing entire theme to enclose variables so they won't bleed out to other themes

.npo3fmkx {

  // ==========================================================================
  // Default variables
  // ==========================================================================

  $primary-color: #FFFF28;
  $assets-path: $assets-path-root + '/npo3fm/kx';


  // ==========================================================================
  // Maps (add -theme suffix)
  // ==========================================================================

  // Logo
  $logo-theme: (
          path: #{'url(#{isset($assets-path)}/logo.#{isset($logo-image-type, svg)})'},
  );

  // Player
  $player-theme: (
          highlight-color: $primary-color,
  );


  // Styles
  @import "../tools/variables/maps/logo";
  @import "../tools/variables/maps/player";
  @import "molecules/logo";
  @import "molecules/player";


  // ==========================================================================
  // 3FM overrides
  // ==========================================================================

  .playlist .card-item:first-child .card-item__time {
    background: $primary-color;
  }

  .menu__link.is-active {
    color: $primary-color;
  }

  .nieuws-item__content {
    border-left-color: $primary-color;
  }
}
