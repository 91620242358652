/* ==========================================================================
   NPO
   ========================================================================== */

.npo-container {
  float: right;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 4px;
  box-shadow: inset 0 0 1px 2px rgba(black, 0.1);
}

.npo-logo {
  width: auto;
  height: 22px;
}
