/* ==========================================================================
   Video container
   ========================================================================== */

.video-container {
  position: relative;
  flex: 0 0 auto;
  @include ratio-block(16 by 9);
  max-height: 75vh;
  background: hsl(216, 33%, 97%);

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
  }
}
