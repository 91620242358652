// Coverall

$illusion-coverall-offset:          0 !default;
$illusion-coverall-top:             true !default;
$illusion-coverall-right:           true !default;
$illusion-coverall-bottom:          true !default;
$illusion-coverall-left:            true !default;
$illusion-coverall-position:        absolute !default;
$illusion-coverall-reset:           false !default;
$illusion-coverall-reset-position:  false !default;
$illusion-coverall-reset-top:       auto !default;
$illusion-coverall-reset-right:     auto !default;
$illusion-coverall-reset-bottom:    auto !default;
$illusion-coverall-reset-left:      auto !default;
