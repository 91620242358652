/* ==========================================================================
   Gids
   ========================================================================== */

.gids {
  @include spacing(bottom, $lastchildnone: true);

  &:not(:first-child) {
    .gids__day {
      margin-top: 1em;
    }
  }
}

.gids__day {
  margin-bottom: 20px;
  line-height: 1.2em;
  font-weight: $weight-bold;
  font-size: 18px;
}
