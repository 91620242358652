/* ==========================================================================
   Loader
   ========================================================================== */

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 32px;
  text-align: center;
  display: flex;
  align-items: flex-end;
}

.loader > div {
  background-color: rgba(0, 0, 0, 0.75);
  height: 100%;
  width: 6px;
  margin-right: 4px;
  transform-origin: 50% 100%;
  animation: loader-animation 1.2s infinite ease-in-out;
}

.loader .loader__bar2 {
  height: 80%;
  animation-delay: -1.1s;
}

.loader .loader__bar3 {
  height: 90%;
  animation-delay: -1.0s;
}

.loader .loader__bar4 {
  animation-delay: -0.9s;
}

.loader .loader__bar5 {
  height: 70%;
  animation-delay: -0.8s;
}

@keyframes loader-animation {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1.0);
  }
}
