// Content block

@mixin content-block($spacing: $illusion-content-block-spacing, $location: $illusion-content-block-location ) {
  @if $location == top {
    margin-top: $spacing;

    &:first-child {
      margin-top: 0;
    }
  }
  @if $location == bottom {
    margin-bottom: $spacing;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
