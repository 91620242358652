// Default variables
$nposm-background: white !default;
$nposm-color: black !default;

$nposm-header-background: black !default;
$nposm-header-color: white !default;

$nposm-form-element: #000 !default;

$nposm-fromserver-background: white !default;
$nposm-fromclient-background: lightgrey !default;
$nposm-message-name-color: black !default;
$nposm-message-text-color: black !default;

$nposm-message-input-background: black !default;
$nposm-message-input-icons-background: white !default;
$nposm-message-input-icons-color: black !default;
$nposm-message-input-text-background: white !default;
$nposm-message-input-text-color: black !default;


/* ==========================================================================
   Advertisement
   ========================================================================== */

/**
 * Grabbed only items that had a variable as value
 * Edited on march 5 2019
 */


/* Added for popupplayer
   ========================================================================== */

.npo-studio-messenger {
  color: $nposm-color;
  text-align: left;
  font-size: 16px;
  line-height: 24px;

  .message,
  .photo-new,
  .audio-new,
  p,
  label,
  input,
  textarea {
    font-family: "NPO Nova Text", sans-serif;
  }

  .page-profile-create,
  .page-profile-update,
  .page-messages {
    background: $nposm-background;
  }

  .messenger-page-header {
    background: $nposm-header-background;
    color: $nposm-header-color;

    .button-messenger-close,
    .button-messenger-back,
    .button-messenger-profile-update {
      svg {
        fill: $nposm-header-color;
      }
    }
  }

  // Profile
  .page-profile-create,
  .page-profile-update {

    label {
      color: $nposm-form-element;
    }

    input[type=text],
    input[type=tel],
    input[type=email] {
      border: 1px solid $nposm-form-element;
    }

    button[type=submit] {
      border: 2px solid $nposm-header-background;
      background: $nposm-header-background;
      color: $nposm-header-color;
    }

    button[type=submit]:hover {
      background: $nposm-header-background;
      color: $nposm-header-color;
    }
  }

  .messages-list {
    li {
      .message {
        color: $nposm-message-text-color;
      }

      &.from-server {
        .message-content {
          background: $nposm-fromserver-background;
        }
      }

      &.from-client {
        .message-content {
          background: $nposm-fromclient-background;
        }
      }
    }
  }

  .message-new {
    background: $nposm-message-input-background;

    &-inputwrapper {
      background: $nposm-message-input-text-background;

      textarea {
        color: $nposm-message-input-text-color;
        background: $nposm-message-input-text-background;
      }

      .inputbuttons-right {
        .button-message-photo-add {
          svg {
            fill: $nposm-message-input-icons-color;
          }
        }
      }
    }

    .button-message-audio-add {
      background: $nposm-message-input-icons-background;

      svg {
        fill: $nposm-message-input-icons-color;
      }

    }

    .button-message-send {
      background: $nposm-message-input-icons-background;

      svg {
        fill: $nposm-message-input-icons-color;
      }
    }
  }

  .photo-new {
    .photo-container-title {
      background: $nposm-header-background;
      color: $nposm-header-color;
    }

    .photo-text-container {
      background: $nposm-message-input-background;
    }

    .button-photo-send {
      background: $nposm-message-input-icons-background;

      svg {
        fill: $nposm-message-input-icons-color;
      }
    }
  }

  .audio-new {
    .audio-confirm-title {
      background: $nposm-header-background;
      color: $nposm-header-color;
    }

    .audio-preview {
      border: 1px solid $nposm-header-background;
    }

    .button-audio-send {
      border: 2px solid $nposm-header-background;
      background: $nposm-header-color;
      color: $nposm-header-background;
    }
  }
}
