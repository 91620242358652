/* ==========================================================================
   Subchannel container, holding the button on the top bar
   ========================================================================== */

.subchannel-container {
  position: relative;
  margin-right: $spacing-m;
  margin-left: auto;

  @include breakpoint($charlie) {
    display: none;
  }

  .subchannel-selector {
    top: calc(100% + 5px);
    right: -20px;
    bottom: auto;
    left: auto;
  }
}

/* ==========================================================================
   Subchannel button
   ========================================================================== */

.subchannel-button {
  @include reset($margin: false, $padding: 0 32px 0 1em);
  color: inherit;
  line-height: 1.5em;
  min-height: 2.5rem;

  /* Chevron element */
  @include pseudo(after) {
    top: 50%;
    right: .5rem;
    width: 16px;
    height: 16px;
    @include svg-background('<path d="M23.521,16.21l-10.442,-10.877c-0.588,-0.613 -1.569,-0.613 -2.158,0l-10.441,10.877c-0.64,0.667 -0.64,1.72 0,2.387c0.678,0.706 1.807,0.706 2.486,0l8.746,-9.109c0.158,-0.166 0.42,-0.166 0.578,0l8.745,9.109c0.678,0.706 1.807,0.706 2.486,0c0.319,-0.334 0.479,-0.763 0.479,-1.193c0,-0.43 -0.16,-0.86 -0.479,-1.194Z"/>', white, 16, 16, $background-position: center right);
    transform: translateY(-50%) rotate(180deg);
  }
}


/* ==========================================================================
   Subchannel selector list
   ========================================================================== */

.subchannel-selector {
  position: absolute;
  z-index: 9;
  bottom: 100%;
  left: -20px;
  min-width: 100%;
  height: auto;
  background-color: #2d2d2d;
  visibility: hidden;
  opacity: 0;
  transform: scaleY(0);
  transition: visibility 0s linear 200ms, transform 200ms ease-out, opacity 200ms ease-out;
  transform-origin: 0 0;

  &.is-open {
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);
    transition-delay: 0s;
  }

  ul {
    @include reset();
    text-align: left;
  }

  li {
    position: relative;
    z-index: 1;
    line-height: 32px;

    + li {
      margin-top: -8px;
    }

    @include hover {
      z-index: 2;
    }
  }

  a {
    display: block;
    text-decoration: none;
    white-space: nowrap;
    padding: 16px 20px;
    line-height: 1;
    @include transition(background);

    @include hover {
      background: black;
    }
  }
}
