/* ==========================================================================
   Player
   ========================================================================== */

.player__content {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  height: $player-height;
  width: 100%;
  @include spacing(horizontal);
  background: rgba(#000, .4);
  color: #ffffff;
  text-align: center;
  backdrop-filter: blur(20px);

  iframe {
    position: absolute;
  }
}

.player__section {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}

.player__section--channel,
.player__section--volume {
  position: relative;
  display: none;
  height: 100%;

  @include breakpoint($charlie) {
    display: flex;
    flex-basis: 20%;
  }
}

.player__section--buttons {
  flex-grow: 0;
}

/* Button
   ========================================================================== */

.player__button {
  @include reset($margin: 0 .25rem);
  overflow: hidden;
  width: auto;
  padding-top: 28px;
  padding-right: 1em;
  padding-bottom: .5em;
  padding-left: 1em;
  color: inherit;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border-radius: .4rem;
  @include transition(background-color);

  @include hover {
    background-color: rgba(#333, .8);
  }

  @include breakpoint($charlie) {
    min-width: 32px;
    padding-top: 32px;
  }

  &.is-playing, &.is-paused, &.is-loading {
    text-indent: -999px;
  }
}

/**
 * Channel button
 *
 * Extending the .subchannel-button
 */

.player__button--channel {
  margin-right: auto;
  margin-left: 0;
  padding-top: 0;
  font-size: 16px;

  /* Rotate original chevron SVG */

  &.subchannel-button {
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }

    &:nth-last-child(3) {
      &:after {
        transform: translateY(-50%);
      }
    }
  }

  & ~ .subchannel-selector {
    transform-origin: 0 100%;
  }
}

/* Appje button */

.player__button--appje {
  @include svg-background('<g transform="matrix(0.96,0,0,0.96,0,-3.76019e-11)"><path d="M21.875,0L3.125,0C1.401,0 0,1.401 0,3.125L0,17.186C0,18.91 1.401,20.311 3.125,20.311L7.813,20.311L7.813,24.413C7.813,24.891 8.359,25.169 8.745,24.886L14.844,20.311L21.875,20.311C23.599,20.311 25,18.91 25,17.186L25,3.125C25,1.401 23.599,0 21.875,0ZM14.063,12.89C14.063,13.105 13.887,13.28 13.672,13.28L6.641,13.28C6.426,13.28 6.25,13.105 6.25,12.89L6.25,12.109C6.25,11.894 6.426,11.718 6.641,11.718L13.672,11.718C13.887,11.718 14.063,11.894 14.063,12.109L14.063,12.89ZM18.75,8.203C18.75,8.417 18.574,8.593 18.359,8.593L6.641,8.593C6.426,8.593 6.25,8.417 6.25,8.203L6.25,7.421C6.25,7.207 6.426,7.031 6.641,7.031L18.359,7.031C18.574,7.031 18.75,7.207 18.75,7.421L18.75,8.203Z" /></g>', white, 20, 20, $background-position: center 4px);

  @include breakpoint($charlie) {
    @include svg-background('<g transform="matrix(0.96,0,0,0.96,0,-3.76019e-11)"><path d="M21.875,0L3.125,0C1.401,0 0,1.401 0,3.125L0,17.186C0,18.91 1.401,20.311 3.125,20.311L7.813,20.311L7.813,24.413C7.813,24.891 8.359,25.169 8.745,24.886L14.844,20.311L21.875,20.311C23.599,20.311 25,18.91 25,17.186L25,3.125C25,1.401 23.599,0 21.875,0ZM14.063,12.89C14.063,13.105 13.887,13.28 13.672,13.28L6.641,13.28C6.426,13.28 6.25,13.105 6.25,12.89L6.25,12.109C6.25,11.894 6.426,11.718 6.641,11.718L13.672,11.718C13.887,11.718 14.063,11.894 14.063,12.109L14.063,12.89ZM18.75,8.203C18.75,8.417 18.574,8.593 18.359,8.593L6.641,8.593C6.426,8.593 6.25,8.417 6.25,8.203L6.25,7.421C6.25,7.207 6.426,7.031 6.641,7.031L18.359,7.031C18.574,7.031 18.75,7.207 18.75,7.421L18.75,8.203Z" /></g>', white, 26, 26, $background-position: center 2px);
  }
}

/* Play / Pause button */

.player__button--toggle {
  width: 68px;
  padding-top: 44px;
  overflow: visible;
  font-size: 0;

  @include pseudo(before) {
    opacity: 0;
    background: transparent;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 50% 50%;
    border-radius: 50%;
    width: 52px;
    height: 52px;
    border: 8px solid #fff;
  }

  /* When loading show loading icon */

  &.is-loading {
    opacity: 0.3;
  }

  /* When paused show play icon */

  &.is-paused, &.is-loading {
    @include svg-background('<g transform="matrix(0.533333,0,0,0.542483,0,1.42109e-14)"><path d="M22.5,-0C10.125,-0 0,9.954 0,22.121C0,34.287 10.125,44.241 22.5,44.241C34.875,44.241 45,34.287 45,22.121C45,9.954 34.875,-0 22.5,-0ZM18,30.095L18,14.146C18,13.594 18.448,13.146 19,13.146C19.214,13.146 19.422,13.214 19.593,13.341L30.408,21.316C30.853,21.643 30.948,22.269 30.62,22.714C30.56,22.795 30.489,22.866 30.408,22.925L19.593,30.9C19.149,31.228 18.523,31.133 18.195,30.688C18.068,30.517 18,30.309 18,30.095Z" /></g>', white, 44, 44);
  }

  /* When playing show pause icon */

  &.is-playing {
    @include svg-background('<g transform="matrix(0.436364,0,0,0.436364,1.77636e-15,1.77636e-15)"><path d="M27.5,0C42.625,0 55,12.375 55,27.5C55,42.625 42.625,55 27.5,55C12.375,55 0,42.625 0,27.5C0,12.375 12.375,0 27.5,0ZM37,18L18,18L18,37L37,37L37,18Z" /></g>', white, 44, 44);

    &::before {
      animation: playing .2s ease-out forwards;
    }
  }

  @keyframes playing {
    0% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(2.2);
    }
  }

  /* Large button on larger screens */

  @include breakpoint($charlie) {
    padding-top: 48px;
  }
}

/* Live button */

.player__button--live {
  @include svg-background('<g transform="matrix(0.96,0,0,0.96,4.44089e-16,4)"><path d="M14.592,-0L2.075,-0C0.929,-0 0,0.929 0,2.075L0,14.592C0,15.738 0.929,16.667 2.075,16.667L14.592,16.667C15.738,16.667 16.667,15.738 16.667,14.592L16.667,2.075C16.667,0.929 15.738,-0 14.592,-0ZM22.813,1.636L18.056,4.918L18.056,11.749L22.813,15.026C23.733,15.66 25,15.013 25,13.906L25,2.756C25,1.654 23.737,1.003 22.813,1.636Z" /></g>', white, 20, 20, $background-position: center 4px);

  @include breakpoint($charlie) {
    @include svg-background('<g transform="matrix(0.96,0,0,0.96,4.44089e-16,4)"><path d="M14.592,-0L2.075,-0C0.929,-0 0,0.929 0,2.075L0,14.592C0,15.738 0.929,16.667 2.075,16.667L14.592,16.667C15.738,16.667 16.667,15.738 16.667,14.592L16.667,2.075C16.667,0.929 15.738,-0 14.592,-0ZM22.813,1.636L18.056,4.918L18.056,11.749L22.813,15.026C23.733,15.66 25,15.013 25,13.906L25,2.756C25,1.654 23.737,1.003 22.813,1.636Z" /></g>', white, 26, 26, $background-position: center 2px);
  }

  &.is-active {
    overflow: visible;
  }
}

/* Volume button */

.player__button--volume {
  margin-right: 0;
  margin-left: auto;
  width: 40px;
  height: 0;
  padding: 40px 0 0 0;
  @include svg-background('<g transform="matrix(0.953846,0,0,0.953846,2.22045e-16,2.44898)"><path d="M11.272,0.367L6.608,5.006L1.258,5.006C0.563,5.006 0,5.567 0,6.258L0,13.768C0,14.459 0.563,15.02 1.258,15.02L6.608,15.02L11.272,19.659C12.06,20.442 13.419,19.892 13.419,18.774L13.419,1.252C13.419,0.133 12.059,-0.415 11.272,0.367ZM25.161,10.013C25.161,6.7 23.481,3.654 20.665,1.865C20.079,1.492 19.301,1.666 18.929,2.254C18.558,2.842 18.731,3.621 19.318,3.994C21.401,5.318 22.645,7.568 22.645,10.013C22.645,12.458 21.401,14.708 19.318,16.032C18.731,16.405 18.558,17.183 18.929,17.772C19.27,18.313 20.036,18.562 20.665,18.161C23.481,16.373 25.161,13.326 25.161,10.013ZM17.73,6.004C17.123,5.674 16.357,5.891 16.02,6.497C15.685,7.102 15.907,7.863 16.516,8.198C17.193,8.567 17.613,9.264 17.613,10.013C17.613,10.763 17.193,11.459 16.516,11.828C15.908,12.163 15.686,12.924 16.021,13.529C16.358,14.137 17.124,14.353 17.73,14.022C19.21,13.211 20.13,11.675 20.13,10.013C20.13,8.35 19.21,6.815 17.73,6.004Z" /></g>', white, 20, 20);

  &:nth-last-child(3) {
    background-color: rgba(#333333, .8);
  }
}

/* Volume slider
   ========================================================================== */

.volume-slider {
  position: absolute;
  z-index: 9;
  right: 0;
  bottom: calc(100% - 12px);
  width: 40px;
  height: 120px;
  background: rgba(#333333, .8);
  border-radius: .4rem;
  transition: transform .05s ease-out;
  transform-origin: 50% 100%;

  &.is-open {
    transform: scaleY(1);
    transition-duration: 150ms;
  }

  &.is-closed {
    transform: scaleY(0);
  }

  .rc-slider-vertical {
    margin: 10px auto 10px;
    height: 100px;
    width: 16px;

    .rc-slider-rail {
      background-color: white;
      width: 6px;
      border-radius: 3px;
    }

    .rc-slider-track {
      width: 6px;
      border-radius: 3px;
    }

    .rc-slider-step {
      width: 6px;
    }

    .rc-slider-handle {
      box-shadow: 0 2px 2px rgba(#000, .15);
      margin-left: -4px;
    }
  }
}
