/* Card item
   ========================================================================== */

.card-item {
  @include property(background, $card, background);
  @include property(color, $card, color);
  @include property(font-family, $card, font-family);
  @include property(font-size, $card, font-size);
  @include property(font-weight, $card, font-weight);
  @include property(text-transform, $card, text-transform);
  @include property(border-radius, $card, border-radius);

  .flag-object__body {
    @include property(padding, $card, padding);
  }
}

/* Card item time
   ========================================================================== */

.card-item__time {
  @include property(color, $card, item-time-color);
  @include property(font-family, $card, item-time-font-family);
  @include property(font-weight, $card, item-time-font-weight);
  @include property(background-color, $card, item-time-background-color);
  @include property(border-radius, $card, item-time-border-radius);
}

.playlist {
  .card-item:first-child {
    .card-item__time {
      @include property(color, $card, item-time-highlight-color);
      @include property(background, $card, item-time-highlight-background);
      @include property(border-radius, $card, item-time-highlight-border-radius);
    }
  }
}


/* Card item image
   ========================================================================== */

.card-item__image {
  @include property(border-radius, $card, item-image-border-radius);
}


/* Card item track
   ========================================================================== */

.card-item__title {
  @include property(color, $card, item-track-color);
  @include property(font-family, $card, item-track-font-family);
  @include property(font-size, $card, item-track-font-size);
  @include property(font-weight, $card, item-track-font-weight);
  @include property(line-height, $card, item-track-line-height);
  @include property(text-transform, $card, item-track-text-transform);

  @include breakpoint($charlie) {
    @include property(font-size, $card, item-track-charlie-font-size);
    @include property(line-height, $card, item-track-charlie-line-height);
  }
}

/* Card item artist
   ========================================================================== */

.card-item__subtitle {
  @include property(color, $card, item-artist-color);
  @include property(font-family, $card, item-artist-font-family);
  @include property(font-style, $card, item-artist-font-style);
  @include property(font-size, $card, item-artist-font-size);
  @include property(font-weight, $card, item-artist-font-weight);
  @include property(line-height, $card, item-artist-line-height);
  @include property(text-transform, $card, item-artist-text-transform);

  @include breakpoint($charlie) {
    @include property(font-size, $card, item-artist-charlie-font-size);
    @include property(line-height, $card, item-artist-charlie-line-height);
  }
}
