/* ==========================================================================
   Flag object
   ========================================================================== */

.flag-object {
  display: flex;
}

.flag-object__aside {
  flex: 0 0 auto;
}

.flag-object__body {
  flex: 1 1 auto;
}
