@if ($illusion-extendalize == true and $illusion-extendalize-heading != false) or $illusion-extendalize-heading == true {
  /**
   * Heading
   *
   * 1. Set font-weight to bold to stand out
   * 2. Reset font-size to be as big as parent font size.
        Set font-sizes with classes
   * 3. Remove top-margin, margins should live on bottom of elements
   * 4. Set bottom margin to default line-height and remove for last-child. A bit smaller to visually group headings with content below
   */

  h1, h2, h3, h4, h5, h6 {
    font-weight: $illusion-extendalize-heading-weight; /* 1 */
    font-size: $illusion-extendalize-heading-font-size; /* 2 */
    margin-top: $illusion-extendalize-heading-margin-top; /* 3 */
    @include content-block($illusion-extendalize-heading-margin-bottom); /* 4 */
  }
}
