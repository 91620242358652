/* Track item track
   ========================================================================== */

.show__name {
  @include property(color, $show, name-color);
  @include property(font-family, $show, name-font-family);
  @include property(font-size, $show, name-font-size);
  @include property(font-weight, $show, name-font-weight);
  @include property(line-height, $show, name-line-height);
  @include property(text-transform, $show, name-text-transform);

  @include breakpoint($charlie) {
    @include property(color, $show, name-charlie-color);
    @include property(font-size, $show, name-charlie-font-size);
    @include property(line-height, $show, name-charlie-line-height);
  }
}

/* Track item artist
   ========================================================================== */

.show__time {
  @include property(color, $show, time-color);
  @include property(font-family, $show, time-font-family);
  @include property(font-size, $show, time-font-size);
  @include property(font-style, $show, time-font-style);
  @include property(font-weight, $show, time-font-weight);
  @include property(line-height, $show, time-line-height);
  @include property(text-transform, $show, time-text-transform);

  @include breakpoint($charlie) {
    @include property(color, $show, time-charlie-color);
    @include property(font-size, $show, time-charlie-font-size);
    @include property(line-height, $show, time-charlie-line-height);
  }
}
