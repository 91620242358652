// Reset elements

@mixin reset($margin: $illusion-reset-margin, $padding: $illusion-reset-padding, $list-style: $illusion-reset-list-style, $border: $illusion-reset-border, $background: $illusion-reset-background) {
  @if $margin != false {
    margin: $margin;
  }
  @if $padding != false {
    padding: $padding;
  }
  @if $list-style != false {
    list-style: $list-style;
  }
  @if $border != false {
    border: $border;
  }
  @if $background != false {
    background: $background;
  }
}
