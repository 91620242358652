// Set a CSS property and value but only if value is not "inherit" unless $inherit is set to true
@mixin property($property, $map, $value) {

  // Get the actual value
  $val: map-get($map, $value);

  // If a value exists inside the map
  @if $val {

    // If the value is not "inherit"
    @if ($val != inherit) {
      #{$property}: $val;
    }
  }
}
