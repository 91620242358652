// Pseudo elements

@mixin pseudo($illusion-pseudo: $illusion-pseudo, $parent-position: $illusion-pseudo-parent-position, $position: $illusion-pseudo-position, $content: $illusion-pseudo-content, $display: $illusion-pseudo-display) {
  @if $parent-position != false {
    position: $parent-position;
  }

  &:#{$illusion-pseudo} {
    @if $position != false {
      position: $position;
    }
    @if $content != false {
      content: $content;
    }
    @if $display != false {
      display: $display;
    }
    @content;
  }
}
