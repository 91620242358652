/* ==========================================================================
   Navigation
   ========================================================================== */

.navigation {
  @include property(background, $navigation, background);
  @include property(color, $navigation, color);
  @include property(font-family, $navigation, font-family);
  @include property(font-size, $navigation, font-size);
  @include property(font-style, $navigation, font-style);

  @include breakpoint($charlie) {
    @include property(font-size, $navigation, charlie-font-size);
  }
}

/* Menu link
   ========================================================================== */

.menu__link {
  @include property(font-weight, $navigation, link-font-weight);
  @include property(text-transform, $navigation, link-text-transform);

  @include hover {
    @include property(background, $navigation, link-hover-background);
    @include property(color, $navigation, link-hover-color);
    @include property(text-decoration, $navigation, link-hover-text-decoration);
  }

  &.is-active {
    @include property(background, $navigation, link-active-background);
    @include property(color, $navigation, link-active-color);
    @include property(text-decoration, $navigation, link-active-text-decoration);
  }
}


$underline: map-get($navigation, link-active-underline);
@if ($underline != false) {
  .menu__link.is-active {
    @include pseudo(after) {
      @include property(right, $navigation, link-active-underline-right);
      @include property(bottom, $navigation, link-active-underline-bottom);
      @include property(left, $navigation, link-active-underline-left);
      @include property(height, $navigation, link-active-underline-height);
      @include property(background, $navigation, link-active-underline-background);
    }
  }
}