/* ==========================================================================
   Image placeholder
   ========================================================================== */

.image-placeholder {
  position: relative;
  background-color: hsl(214, 15%, 91%);
  @include svg-background('<path d="M0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm11 9l-3-3-6 6h16l-5-5-2 2zm4-4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>', '#616E7C', 24, 24, 20, 20);

  .image {
    @include coverall();
    background-size: cover;
  }
}

.image-placeholder--1-1 {
  @include ratio-block(1 by 1);
}

.image-placeholder--4-3 {
  @include ratio-block(4 by 3);
}
