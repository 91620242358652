@if ($illusion-extendalize == true and $illusion-extendalize-paragraph != false) or $illusion-extendalize-paragraph == true {
  /**
   * Paragraph
   *
   * 1. Remove top-margin, margins should live on bottom of elements
   * 2. Set bottom margin to default line-height and remove for last-child
   */

  p {
    margin-top: $illusion-extendalize-paragraph-margin-top; /* 1 */
    @include content-block($illusion-extendalize-paragraph-margin-bottom); /* 2 */
  }
}
