// Font-family settings

$font-family:             "NPO Nova Text", sans-serif !default;
$font-size:               16px !default;
$line-height:             24px !default;


// Font-weight settings

$weight-light:            300 !default;
$weight-normal:           400 !default;
$weight-bold:             700 !default;


// Fluid type settings

$illusion-fluid-type-min-value:   $font-size !default;
$illusion-fluid-type-max-value:   20px !default;
$illusion-fluid-type-min-screen:  $bravo !default;
$illusion-fluid-type-max-screen:  $delta !default;
