@if ($illusion-extendalize == true and $illusion-extendalize-figure != false) or $illusion-extendalize-figure == true {
  /**
   * Figure
   */

  figure {
    margin-top: $illusion-extendalize-figure-margin-top;
    margin-right: $illusion-extendalize-figure-margin-right;
    margin-left: $illusion-extendalize-figure-margin-left;
    @include content-block($illusion-extendalize-figure-margin-bottom);
  }
}
