/* ==========================================================================
   Nieuws
   ========================================================================== */

.nieuws-item {
  text-decoration: none;
  @include spacing(bottom, margin, 2, 0 to $bravo, $lastchildnone: true);
  @include pseudo(after) {
    @include coverall();
    @include transition(opacity);
    background: black;
    opacity: 0;
    border-radius: inherit;
  }

  @include hover {
    &:after {
      opacity: 0.1;
    }
  }
}

.nieuws-item__image {
  width: 104px;
  min-height: 80px;
}

.nieuws-item__title {
  margin-top: 4px;
}
