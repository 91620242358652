/* ==========================================================================
   Card
   ========================================================================== */

.card-item {
  position: relative;
  @include spacing(right, margin, 1, 0 to $bravo);
  @include spacing(bottom, margin, 2.5, 0 to $bravo, $lastchildnone: true);
  @include spacing(left, margin, 1, 0 to $bravo);
  box-shadow: 0 0 10px 0 rgba(#000, .15);

  .flag-object__aside {
    flex-basis: 72px;
  }

  .flag-object__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.card-item__time {
  position: absolute;
  z-index: 2;
  @include spacing(top, margin, -1, 0 to $bravo);
  @include spacing(left, margin, -1, 0 to $bravo);
  font-size: 12px;
  height: 24px;
  padding-right: .5em;
  padding-left: .5em;
  display: flex;
  align-items: center;
}

.card-item__image {
  position: relative;
  overflow: hidden;

  img {
    @include coverall();
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.card-item--transparent {
  @include triple-self {
    background: transparent;
    padding: 0;
  }
}

.card-item__subtitle,
.card-item__presenter {
  margin-top: 4px;
}
