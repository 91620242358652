// Aspect ratio blocks

@mixin ratio-block($ratio: $illusion-ratio-block, $overflow: $illusion-ratio-block-overflow, $after: $illusion-ratio-block-after) {

  // Variables
  $width: nth($ratio, 1);
  $height: nth($ratio, 3);
  $percent: calc($height / $width) * 100%;

  @if $overflow != false {
    overflow: $overflow;
  }

  &:before {
    display: $illusion-ratio-block-before-display;
    float: $illusion-ratio-block-before-float;
    content: $illusion-ratio-block-before-content;
    padding-top: $percent;
  }

  @if $after != false {
    &:after {
      content: $illusion-ratio-block-after-content;
      clear: $illusion-ratio-block-after-clear;
      display: $illusion-ratio-block-after-display;
    }
  }
}
