// Breakpoint
// Sets a temporary global $inside-breakpoint variable to be used inside other mixins

@mixin breakpoint($width, $width--max: $illusion-breakpoint-width-max) {
  $inside-breakpoint: $width !global;
  @if $width--max == false {
    @media screen and (min-width: $width) {
      @content;
    }
  } @else {
    @media screen and (min-width: $width) and (max-width: $width--max) {
      @content;
    }
  }
  $inside-breakpoint: false !global;
}