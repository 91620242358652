/* ==========================================================================
   Header
   ========================================================================== */

.header {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  height: 96px;
  margin-bottom: 48px;

  @include breakpoint($charlie) {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    height: 176px;
    margin-bottom: 0;
  }
}

/* Header image
   ========================================================================== */

.header__image {
  flex: 0 0 96px;
  width: 96px;
  height: 96px;

  @include breakpoint($charlie) {
    position: absolute;
    flex: 0 0 176px;
    width: 176px;
    height: 176px;
  }
}

/* Header body
   ========================================================================== */

.header__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  max-width: calc(100% - 96px);
  @include spacing(horizontal, padding, 1.5);

  @include breakpoint($charlie) {
    flex: 0 0 auto;
    margin-left: 176px;
  }
}


/* Header footer
   ========================================================================== */

.header__footer {
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  height: 48px;
  max-width: 100%;
  @include spacing(horizontal, padding, 1.5);

  @include breakpoint($charlie) {
    flex: 0 0 auto;
    margin-left: 176px;
    height: auto;

    @include pseudo(before) {
      top: 0;
      left: spacing(1.5);
      right: spacing(1.5);
      height: 1px;
      border-top: 1px solid;
    }

    @include triple-self {
      background: transparent;
      color: inherit;
    }
  }
}
