// Default transition
//
// Usage:   @include transition(width, height 0.3s ease-in-out);
// Output:  transition(width 0.2s, height 0.3s ease-in-out);
//
// Pass in any number of transitions

@mixin transition($transitions...) {
  $unfoldedTransitions: ();
  @if length($transitions) == 0 {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition(all), comma);
  }
  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
  }
  transition: $unfoldedTransitions;
}


// Default transition settings
//
// https://gist.github.com/tobiasahlin/7a421fb9306a4f518aab

@function unfoldTransition ($transition) {
  // Default values
  $property: all;
  $duration: $illusion-transition-duration;
  $easing: $illusion-transition-easing;
  $delay: $illusion-transition-delay;
  $defaults: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaults) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i)
    } @else {
      $p: nth($defaults, $i)
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}
