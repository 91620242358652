/* Show
   ========================================================================== */

.show {
  display: flex;
}

.show__content {
  flex: 0 1 auto;
  overflow: hidden;
}

.show__image {
  margin-left: $spacing-s;
  flex: 0 0 auto;
}

.show__divide {
  display: inline-block;
  width: 2rem;
  height: 1.5em;

  @include pseudo(after) {
    top: calc(50% - 3px);
    left: calc(50% - 3px);
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: currentColor;
  }
}

.show__top {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.show__broadcaster {
  font-weight: $weight-normal;
}