// Namespacing entire theme to enclose variables so they won't bleed out to other themes

.nporadio4 {
    // ==========================================================================
    // Default variables
    // ==========================================================================

    // Color
    $primary-color: #761969;
    $primary-color-color: #ffffff;
    $secondary-color: #073248;
    $secondary-color-color: #ffffff;
    $tertiary-color: #dddddd;
    $tertiary-color-color: $secondary-color;


    // Paths
    $assets-path: $assets-path-root + "/nporadio4";

    // Logo
    $logo-width: 48px;

    // ==========================================================================
    // Maps (add -theme suffix)
    // ==========================================================================

    // Top
    $top-theme: (
        background: $tertiary-color,
        color: $tertiary-color-color,
    );

    // Header
    $header-theme: (
        background: $primary-color #{url("#{$assets-path}/npo-klassiek-vorm.svg")}
            top -36px right -111px / 489px no-repeat,
    );

    // Navigation
    $navigation-theme: (
        charlie-font-size: 16px,
        link-hover-color: $tertiary-color,
        link-active-color: #ffffff,
        link-active-underline: true,
        link-active-underline-height: 3px,
        link-active-underline-background: $primary-color,
    );

    // Card
    $card-theme: (
        border-radius: 5px,
        color: #000,
        background: #fff,
        item-time-color: $secondary-color-color,
        item-time-background-color: $secondary-color,
        item-time-border-radius: 4px,
        item-time-highlight-color: $primary-color-color,
        item-time-highlight-background: $primary-color,
        item-image-border-radius: 5px 0 0 5px,
    );

    // Nieuws
    $nieuws-theme: (
        border-radius: 5px,
        color: #000,
        background: transparent,
        content-border-left: 2px solid $primary-color,
        category-font-weight: $base-weight-bold,
        category-color: $primary-color,
        image-border-radius: 5px 0 0 5px,
        content-padding: 5px 10px,
    );

    // Frequencies
    $frequencies-theme: (
        map: true,
        row-color: $tertiary-color-color,
        row-background: #f9f9f9,
        row-button-color: $primary-color-color,
        row-button-background: $primary-color,
        row-button-border-radius: 5px,
    );

    // ==========================================================================
    // Studiomessenger
    // ==========================================================================

    $nposm-header-background: $primary-color;
    $nposm-header-color: $primary-color-color;

    // Styles
    @import "style";

    // ==========================================================================
    // Extra styling (keep to a minimum)
    // ==========================================================================

    .header__footer::before {
        display: none;
    }

    // Playlist composer (only for R4 that's why we don't have it available in the map)
    .card-item__composer {
        color: $primary-color;
        font-weight: 700;
    }

    // Nieuws
    .nieuws-item__title {
        line-height: 1.25em;
    }
}
