/* ==========================================================================
   Overflowing content
   ========================================================================== */

.overflow-ellipsis {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
