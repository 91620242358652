@if ($illusion-extendalize == true and $illusion-extendalize-picture != false) or $illusion-extendalize-picture == true {
  /**
   * Picture
   *
   * 1. Set img vertical align to middle within picture element
   */

  picture {
    img {
      vertical-align: $illusion-extendalize-picture-img-vertical-align; /* 1 */
    }
  }
}
