// Appje
.appje {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: white;
  color: black;
  padding: 32px 16px;
  box-sizing: border-box;
  visibility: hidden;
  transform: translateX(+100%);
  transition: visibility 0s linear 300ms, transform 0.3s ease-out;

  &.is-open {
    visibility: visible;
    transform: translateX(0);
    transition-delay: 0s;
  }
}

.is-hidden {
  display: none;
}

// Cannot use display none as the audio wouldn't play
#npoplayer iframe {
  width: 0;
  height: 0;
  border: 0;
}
